import React from 'react';
import { logout, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as ElrondLogo } from './../../../assets/img/elrond.svg';
import logo from './../../../assets/img/dapp-logo.png';
import btn1 from './../../../assets/img/logout1.png';
import btn2 from './../../../assets/img/logout2.png';

const Navbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar className='px-4 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name text-muted'>{dAppName}</span>
          <img src={logo} alt='logo' className='heroes-logo' />
        </Link>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <img
                src={btn1}
                alt='logout'
                className='btn-img'
                onMouseOver={(e) => ((e.target as HTMLImageElement).src = btn2)}
                onMouseOut={(e) => ((e.target as HTMLImageElement).src = btn1)}
                onClick={handleLogout}
              />
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
