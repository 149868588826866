import * as React from 'react';
import {
  transactionServices,
  useGetAccountInfo,
  refreshAccount,
  useGetNetworkConfig
} from '@elrondnetwork/dapp-core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBalance } from 'apiRequests';
import { contractAddress, betAmounts } from 'config';

import bk from './../../../assets/img/bk.png';
import you from './../../../assets/img/you.png';
import logo from './../../../assets/img/logo.png';
import la1 from './../../../assets/img/la1.png';
import la2 from './../../../assets/img/la2.png';
import la3 from './../../../assets/img/la3.png';
import ha1 from './../../../assets/img/ha1.png';
import ha2 from './../../../assets/img/ha2.png';
import ha3 from './../../../assets/img/ha3.png';
import pa1 from './../../../assets/img/pa1.png';
import pa2 from './../../../assets/img/pa2.png';
import pa3 from './../../../assets/img/pa3.png';
import {
  Address,
  ContractFunction,
  ProxyProvider,
  Query
} from '@elrondnetwork/erdjs/out';

const Actions = () => {
  const account = useGetAccountInfo();
  const { network } = useGetNetworkConfig();
  const balance =
    Math.round((parseInt(account.account.balance) / 10 ** 18) * 100) / 100;

  const [move, setMove] = React.useState<number>(-1);
  const [bet, setBet] = React.useState<number>();
  const [volume, setVolume] = React.useState<number>();

  const [transactionSessionId, setTransactionSessionId] = React.useState<
    string | null
  >(null);

  const transactionStatus = transactionServices.useTrackTransactionStatus({
    transactionId: transactionSessionId,
    onSuccess: async () => {
      const txs = await fetch(
        `${network.apiAddress}/transactions?receiver=${contractAddress}`
      ).then((res) => res.json());
      window.location.href = `/transaction?txHash=${txs[0]['txHash']}`;
    }
  });

  const { sendTransactions } = transactionServices;

  const sendPlayTransaction = async () => {
    const playTransaction = {
      value: `${bet! * 10 ** 18}`,
      data: `play@0${move! + 1}`,
      gasLimit: 5000000,
      receiver: contractAddress
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: playTransaction
      // callbackRoute: routeNames.transaction,
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  React.useEffect(() => {
    const queryVolume = async () => {
      const query = new Query({
        address: new Address(contractAddress),
        func: new ContractFunction('getVolume')
      });
      const proxy = new ProxyProvider(network.apiAddress);
      proxy
        .queryContract(query)
        .then(({ returnData }) => {
          const [encoded] = returnData;
          const decoded = Buffer.from(encoded, 'base64').toString('hex');
          setVolume(parseInt(decoded, 16) / 10 ** 18);
        })

        .catch((err) => {
          console.error('Unable to call VM query', err);
        });
    };

    queryVolume();
  }, []);

  return (
    <div className='shifumi-actions-container'>
      <div className='infos-container'>
        <img src={logo} alt='shield' />
        {volume && (
          <div>{Math.round(volume * 10) / 10} EGLD fell in the arena</div>
        )}
      </div>
      <div className='heroes-container'>
        <img src={you} alt='you' />
        <img src={bk} alt='black knight' />
      </div>
      <div className='choice-container'>
        <div className='choice-move-container'>
          Select your move
          <div className='moves-container'>
            {[la1, ha1, pa1].map((elt, i) => {
              let clicked = '';
              let hovered = '';
              if (i === 0) {
                hovered = la2;
                clicked = la3;
              } else if (i === 1) {
                hovered = ha2;
                clicked = ha3;
              } else {
                hovered = pa2;
                clicked = pa3;
              }

              return (
                <img
                  src={i === move ? clicked : elt}
                  className='btn-img'
                  key={i}
                  onClick={() => setMove(i)}
                  onMouseOver={(e) => {
                    if (i !== move)
                      (e.target as HTMLImageElement).src = hovered;
                  }}
                  onMouseOut={(e) => {
                    if (i !== move) (e.target as HTMLImageElement).src = elt;
                  }}
                />
              );
            })}
          </div>
        </div>
        {move !== -1 && (
          <div className='choice-bet-container'>
            Select your bet
            <div className='bet-container'>
              {betAmounts.map((betAmount) => {
                return (
                  <button
                    key={betAmount}
                    onClick={() => setBet(betAmount)}
                    className={betAmount === bet ? 'active' : ''}
                  >
                    {betAmount}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {move !== -1 && bet && (
          <div className='action-btn' onClick={sendPlayTransaction}>
            <button className='btn'>
              <FontAwesomeIcon icon={faArrowUp} className='text-primary' />
            </button>
            {/* <a href='/test' className='text-white text-decoration-none'>
              Play
            </a> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Actions;
