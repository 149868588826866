import * as React from 'react';
import { DappUI, useGetNetworkConfig } from '@elrondnetwork/dapp-core';
import { useLocation, Link } from 'react-router-dom';
import { routeNames } from 'routes';

const Transaction = () => {
  const { search } = useLocation();
  const { network } = useGetNetworkConfig();
  const query = new URLSearchParams(search);
  const { txHash } = Object.fromEntries(query);

  const [result, setResult] = React.useState<number>(0);
  const [userAction, setUserAction] = React.useState<number>();
  const [scAction, setScAction] = React.useState<number>();
  const [ended, setEnded] = React.useState<boolean>();

  const fetchResult = async () => {
    await fetch(`${network.apiAddress}/transactions/${txHash}`)
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error('Cannot find transaction');
      })
      .then((json) => {
        for (const result of json['results'])
          if (
            result['data'] &&
            !isNaN(
              parseInt(
                Buffer.from(result['data'], 'base64').toString().split('@')[2]
              )
            )
          ) {
            setResult(
              parseInt(
                Buffer.from(result['data'], 'base64').toString().split('@')[2]
              )
            );
            break;
          }
        setUserAction(
          parseInt(Buffer.from(json['data'], 'base64').toString().split('@')[1])
        );
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      screen.orientation.type.startsWith('portrait')
    ) {
      alert('Make sure to switch to landscape mode!');
    }
    fetchResult();
  }, []);

  React.useEffect(() => {
    // . 1 2 3
    // 1 2 1 3
    // 2 3 2 1
    // 3 1 3 2
    if (result === 2) setScAction(userAction);
    else if (result === 1) {
      if (userAction === 1) setScAction(2);
      else if (userAction === 2) setScAction(3);
      else setScAction(1);
    } else {
      if (userAction === 1) setScAction(3);
      else if (userAction === 2) setScAction(1);
      else setScAction(2);
    }
  }, [userAction]);

  React.useEffect(() => {
    document.body.className = '';
    document.body.classList.add(`r${userAction}-${scAction}`);
    // setTimeout(() => {
    //   document.body.className = '';
    //   setEnded(true);
    // }, 10000);
  }, [userAction, scAction]);

  return (
    <div className='result-container'>
      {ended && (
        <>
          <Link to={routeNames.dashboard}>Play again</Link>
          <a
            href={`${network.explorerAddress}/transactions/${txHash}`}
            target='_blank'
            rel='noreferrer'
          >
            Link to explorer
          </a>
        </>
      )}
    </div>
  );
};

export default Transaction;
