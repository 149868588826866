import axios from 'axios';

interface GetInfoType {
  apiAddress: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    contractAddress,
    timeout
  }: GetInfoType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          receiver: contractAddress,
          condition: 'must',
          withScResults: true,
          size: 10
        },
        timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };
const fetchAPI = (url: string) =>
  async function getContractBalance({
    apiAddress,
    contractAddress,
    timeout
  }: GetInfoType) {
    try {
      const { data } = await axios.get(
        `${apiAddress}${url}${contractAddress}`,
        {
          params: {},
          timeout
        }
      );

      return {
        data: data['balance'],
        success: data['balance'] !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getTransactions = fetchTransactions('/transactions');
export const getBalance = fetchAPI('/accounts/');
