import React from 'react';
import {
  useGetAccountInfo,
  DappUI,
  useGetNetworkConfig
} from '@elrondnetwork/dapp-core';
import moment from 'moment';
import { TransactionType } from './types';
import { contractAddress } from 'config';

function sortByDate(a: TransactionType, b: TransactionType) {
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  return 0;
}

const TransactionsList = ({
  transactions
}: {
  transactions: TransactionType[];
}) => {
  const { network } = useGetNetworkConfig();
  const account = useGetAccountInfo();

  const sortedTransactions: TransactionType[] = (
    [...transactions].filter(
      (el: any) => el !== undefined && el['function'] === 'play'
    ) as any
  ).sort(sortByDate);

  const prettier = (address: string) => {
    return `${address.substring(0, 4)}...${address.substring(58)}`;
  };

  const getTxResult = (tx: TransactionType) => {
    let resU8 = '';
    for (const result of tx['results'])
      if (
        result['data'] &&
        !isNaN(
          parseInt(
            Buffer.from(result['data'], 'base64').toString().split('@')[2]
          )
        )
      ) {
        resU8 = Buffer.from(result['data'], 'base64').toString().split('@')[2];
        break;
      }
    if (resU8 === '01') return 'won';
    else if (resU8 === '02') return 'draw';
    else return 'lost';
  };

  return (
    <div className='recent-fights-container'>
      <h4 className='mb-3 font-weight-normal'>Recent fights</h4>
      {sortedTransactions.map((tx: TransactionType, i) => {
        return (
          <div className='recent-fights-cell' key={tx.txHash + i}>
            <a
              href={`${network.explorerAddress}/transactions/${tx.txHash}`}
              target='_blank'
              rel='noreferrer'
              title='View in Explorer'
            >
              <div className='fight-infos'>
                {prettier(tx.sender)} played{' '}
                <DappUI.Denominate value={tx.value} decimals={2} />{' '}
                {moment.unix(tx.timestamp).fromNow()} and {getTxResult(tx)}
              </div>
            </a>
          </div>
        );
      })}
      <div className='recent-fights-cell'>
        <a
          href={`${network.explorerAddress}/address/${contractAddress}`}
          target='_blank'
          rel='noreferrer'
        >
          See all transactions
        </a>
      </div>
    </div>
  );
};

export default TransactionsList;
