import * as React from 'react';
import Actions from './Actions';
import Transactions from './Transactions';

const Dashboard = () => {
  React.useEffect(() => {
    document.body.className = '';
  }, []);

  return (
    <div className='shifumi-container'>
      <Actions />
      <Transactions />
    </div>
  );
};

export default Dashboard;
