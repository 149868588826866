import * as React from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import btn1 from './../../assets/img/login1.png';
import btn2 from './../../assets/img/login2.png';

const Home = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 col-lg-5 mx-auto'>
          <div className='rounded p-4 border-0'>
            <div className='card-body text-center'>
              <p className='mb-3'>
                <br /> Login using your Elrond wallet.
              </p>

              <Link to={routeNames.unlock}>
                <img
                  src={btn1}
                  alt='login'
                  onMouseOver={(e) =>
                    ((e.target as HTMLImageElement).src = btn2)
                  }
                  onMouseOut={(e) =>
                    ((e.target as HTMLImageElement).src = btn1)
                  }
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
